import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from "gsap/SplitText";
import { spline } from "@georgedoescode/spline";
import { createNoise2D } from 'simplex-noise';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
		// JavaScript
		// if (!Modernizr.objectfit) {
		// 	const objectFitElements = $qsa('.object-fit');

		// 	objectFitElements && [...objectFitElements].forEach(objectFitElement => {
		// 		const image = $qs('img', objectFitElement);
		// 		const imageSrc = image.src;

		// 		if (imageSrc) {
		// 			objectFitElement.style.backgroundImage = `url(${imageSrc})`;
		// 			objectFitElement.classlist.add('not-compatible')
		// 		}
		// 	})
		// }

		// jQuery
		if (!Modernizr.objectfit) {
			$('.object-fit').each(function () {
				var $container = $(this),
					imgUrl = $container.find('img').prop('src');
				if (imgUrl) {
					$container
						.css('backgroundImage', 'url(' + imgUrl + ')')
						.addClass('not-compatible');
				}
			});
		}
	}
	preloader: {
		function preloaderHome() {

			var homeVideo = $('#home-video');
			console.log(homeVideo);
			homeVideo[0].pause();

			var split = new SplitText(".banner__preloader--text .split-text", { type: "lines,chars" });
			var splitHeading = new SplitText(".banner__preloader--text .heading", { type: "chars" });

			var tlBannerScroll = gsap.timeline({ 
				paused: true, immediateRender: 
				false, overwrite: "auto", 
				onComplete: function() { homeVideo[0].play() }
			 });
			var tlBanner = gsap.timeline({ overwrite: "auto" });
			tlBanner
				.fromTo(".banner__preloader--text .split-text > div > div", { opacity: 0, y: '10px' }, { opacity: 1.4, y: 0, duration: 1, stagger: 0.025, ease: "power1.inOut" })
				.fromTo(".banner__preloader--text .heading > div", { opacity: 0, y: '10px' }, { opacity: 1, y: 0, duration: 1.4, stagger: 0.025, ease: "power1.inOut" })
				.fromTo(".banner__preloader--text .bg-stroke__pseudo", { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, duration: 0.6, ease: "power2.inOut" }, "-=0.4")

				.fromTo(".banner__blob", { opacity: 0 }, { opacity: 1, duration: 0.6 })
				.fromTo(".banner__preloader--drawing", { opacity: 0, top: '70%' }, { opacity: 1, top: '60%', duration: 0.6 })

			tlBannerScroll
				.fromTo(".banner__preloader--text", { opacity: 1 }, { opacity: 0, duration: 0.5 })
				// .fromTo(".banner__blob--2", { opacity: 1 }, { opacity: 0, duration: 1, bottom: "-100%", right: "-100%" })
				// .fromTo(".banner__blob.background-teal--mid", { opacity: 1 }, { opacity: 0, duration: 1, top: "-100%", left: "-100%" }, "<")
				.to(".banner__preloader--drawing", { top: 0, duration: 1 }, '-=0.1')
				.to(".banner__preloader", { clipPath: 'inset(0 0 100% 0)', duration: 2, ease: "linear", });
			const vh = (coef) => window.innerHeight * (coef / 100);



			ScrollTrigger.create({
				trigger: '.banner--home',
				pin: '.banner--home',
				start: '5 top',
				end: '+=' + vh(200),
				scrub: 0.1,
				pinSpacing: true,
				animation: tlBannerScroll,
			});
			// var tl = gsap.timeline({
			// 	ease: "power3.inOut",
			// 	onStart: function () {
			// 		$('body').addClass('no-scroll');
			// 	},
			// 	onComplete: function () {
			// 		$('body').removeClass('no-scroll');
			// 	}
			// });

			// tl
			// 	.to($('.js-preloader-text'), {
			// 		// yPercent: -100,
			// 		opacity: 0,
			// 		duration: 2,
			// 	}, ">+3")
			// 	.to($('.js-preloader-image'), {
			// 		top: "0%",
			// 		duration: 3,
			// 	}, "<")
			// 	.to($('.js-preloader-background'), {
			// 		yPercent: -100,
			// 		duration: 5
			// 	}, ">+2")
		};

		function preloaderHomeMobile() {
			var split = new SplitText(".banner__preloader--text .split-text", { type: "lines" });

			// var tlBannerScroll = gsap.timeline({ paused: true, immediateRender: false, overwrite: "auto" });
			var tlBanner = gsap.timeline({ overwrite: "auto" });
			tlBanner
				.fromTo(".banner__preloader--text .split-text > div", { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.6, stagger: 0.3, ease: "power2.out" })
				.fromTo(".banner__preloader--text .heading", { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.6, stagger: 0.3, ease: "power1.out" })
				.fromTo(".banner__preloader--text .bg-stroke__pseudo", { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, duration: 0.3, ease: "power2.inOut" }, "-=0.1")

				.fromTo(".banner__blob", { opacity: 0 }, { opacity: 1, duration: 0.6 })
				.fromTo(".banner__preloader--drawing", { opacity: 0, top: '70%' }, { opacity: 1, top: '28%', duration: 0.6 })

			var homeVideo = $('#home-video');
			
			$(window).scroll(function () {
				if($(window).scrollTop() > (homeVideo.offset().top - 100)) {
					homeVideo[0].play();
				} else {
					homeVideo[0].pause();
				}
				
			})			
			
		}

		if (window.innerWidth > 575) {
			if ($('.js-preloader').length) {
				window.addEventListener('load', function () {

					preloaderHome();
				})
			}
		} else {
			if ($('.js-preloader').length) {
				window.addEventListener('load', function () {
					// homeVideo.play();
					preloaderHomeMobile();
				})
			}
		}
	}
	bgStroke: {
		
		function bgLineAnimate() {
	
			$('.bg-stroke').each(function () {
				let trigger = $(this);
				let bgLine = $(this).find($('.bg-stroke__pseudo'))
				// allow for pin spacer on homepage
				if($('body').hasClass('home')) {
					var startMark = 'top bottom-=' + (window.innerHeight * 2.3);
					console.log('home')
				} else {
					var startMark = 'top bottom+=300px';
				}
				var tl = gsap.timeline({
					scrollTrigger: {
						trigger: trigger,
						markers: false,
						start: startMark,
						scrub: false,
						ease: "power2.out",
						
					}
				});
				if (bgLine.length) {
					if(! bgLine.parent().parent().hasClass('banner__preloader--text')) {
						
						tl.fromTo(bgLine, { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, duration: 0.8, ease: "power2.inOut" }, "-=0.4");
					}
				}
			});	
		}
		bgLineAnimate();		
	}

	buttons : {
		$('.button').each(function() {
			$(this).wrapInner('<span class="button--text"></span>');
			$(this).append('<span class="button--circle"></span>');
			$(this)
				.on('mouseenter', function(e) {
					var parentOffset = $(this).offset(),
					  relX = e.pageX - parentOffset.left,
					  relY = e.pageY - parentOffset.top;
					$(this).find('span.button--circle').css({top:relY, left:relX})
				})
				.on('mouseout', function(e) {
					var parentOffset = $(this).offset(),
					  relX = e.pageX - parentOffset.left,
					  relY = e.pageY - parentOffset.top;
				$(this).find('span.button--circle').css({top:relY, left:relX})
		});
	
		})
	}
	newsTeaserFill : {
		
		$('.news__teaser--link').each(function() {
			$(this).wrapInner('<span class="overlay--news"></span>');

			$(this)
				.on('mouseenter', function(e) {
					var parentOffset = $(this).offset(),
					  relX = e.pageX - parentOffset.left,
					  relY = e.pageY - parentOffset.top;
					$(this).find('.overlay--news').css({top:relY, left:relX})
				})
				.on('mouseout', function(e) {
					var parentOffset = $(this).offset(),
					  relX = e.pageX - parentOffset.left,
					  relY = e.pageY - parentOffset.top;
				$(this).find('.overlay--news').css({top:relY, left:relX})
		});
	
		})
	}
	menuManipulation: {
		// Sports Fixtures and Galleries open in new tab from redirect (redirect so we can have a featured image in the menu)

		$('#menu-item-7222 > a').attr('target','_blank');
		$('#menu-item-7223 > a').attr('target','_blank');
	}
	copyMenuParentLinks: {
		var original = $('.menu-main-container > ul.menu > li');
		// console.log(original);
		original.each(function () {
			// clone the main menu link
			var linkName = $(this).children('a').clone();
			//main menu item featured image
			var imageMove = $(this).children('.thumbnail');
			// sub menu list
			var subList = $(this).find('ul');

			// wrap the sub menu in a div
			subList.wrap("<div class='subwrap'></div>")
			var newHome = $(this).find('.subwrap');
			// add the cloned main menu link to front of sub menu wrapper
			linkName.prependTo(newHome);
			// wrap new title link in a div 
			linkName.wrap("<div class='title-link'></div>");
			// move main menu item featured image to sub menu title link
			imageMove.appendTo($(this).find('.title-link'))
			// add "back menu" to mobile menu
			if (window.innerWidth < 1025) {
				newHome.prepend("<div class='back-menu js-back-menu'>Main menu</div>");
				newHome.append("<div class='back-menu js-back-menu'>Main menu</div>");
			}

		});


		// add body overlay on menu hover {
		$('.menu-main-container > ul.menu > li.menu-item-has-children').mouseover(function () {
			$('body').addClass('menu-open');
		});
		$('.menu-main-container > ul.menu > li.menu-item-has-children').mouseout(function () {
			$('body').removeClass('menu-open');
		});



	}

	mobileMenu: {
		$('.mobile-menu .js-menu-trigger').on('click', function (e) {
			$('.site-menu').toggleClass('open');
			$('.hamburger--button').toggleClass('open');

			//click outside site-menu
			let menu = $('.site-menu');
			let hamburger = $('.js-menu-trigger');
			let menuLink = $('.mobile-menu .menu-item a');
			let backMenu = $('.js-back-menu');

			if (menu.hasClass('open')) {
				$(document.body).on('click', function (e) {

					if (menuLink.is(e.target)) {

						var parentEl = $(e.target.parentNode);
						if (parentEl.hasClass('menu-item-has-children')) {
							e.preventDefault();
							parentEl.find('.subwrap').addClass('open-sub-menu');
						} else {
							$('.site-menu').removeClass('open');
							$('.hamburger--button').removeClass('open');

							$(document.body).off('click');
						}
					}
					if (backMenu.is(e.target)) {
						$(e.target.parentNode).removeClass('open-sub-menu');
					}
				});

			}
		})


	}


	fixedheader: {
		// Javascript
		// const header = $qs('.header');
		// window.addEventListener('scroll', function() {
		// 	const { classlist } = header;
		// 	if (window.pageYOffset >= 150) {
		// 		classlist.add('fixed-header');
		// 	}
		// 	if (window.pageYOffset >= 160) {
		// 		classlist.add('transform-none');
		// 	} else {
		// 		classlist.remove('fixed-header');
		// 		classlist.remove('transform-none');
		// 	}
		// });

		// jQuery
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}

	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}

	menu: {
		// Open submenu on click
		$('.top-nav .menu-item-has-children > a').on('click', function (e) {
			e.preventDefault();

			$('.menu-item-has-children').removeClass('sub-menu-open'); // reset
			$('.sub-menu-wrap').removeClass('sub-menu-open');          // reset

			$(this).closest('.menu-item-has-children').toggleClass('sub-menu-open');
			$(this).next('.sub-menu-wrap').toggleClass('sub-menu-open');
		});
		// Open menu with tabs - accessibility
		$('.top-nav .sub-menu-wrap a').on('focusin', function () {
			$(this).closest('.menu-item-has-children').addClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').addClass('sub-menu-open');

			console.log($(this));
		});
		$('.top-nav .sub-menu-wrap a').on('focusout', function () {
			$(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
		});
		// Close on click outside.
		$(document).on('click', function (e) {
			if (!$(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0) {
				$('.menu-item-has-children').removeClass('sub-menu-open');
				$('.sub-menu-wrap').removeClass('sub-menu-open');
			}
		});
	}

	tableWrap: {
		$('.tablepress').each(function () {
			if (!$(this).parent().hasClass('table')) {
				$(this).wrap("<div class='table'></div>");
			}
		})
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});

		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			
			
			if (!a.test(this.href)) {
				
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		}); 
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}

	slider: {
		$('.full-width-slider').slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
		});
		$('.js-quote-slider').slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
			adaptiveHeight: false
		});

		/**
		 * Gallery slider with thumbnails navigation.
		 */
		$('.gallery-slider').each(function (index) {
			var status = $(this).find('.gallery-slider__full-nav .pages');
			var slickElement = $(this).find('.gallery-slider__full');
			var slickElementThumb = $(this).find('.gallery-slider__thumb');

			slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				var i = (currentSlide ? currentSlide : 0) + 1;
				status.text(i + ' of ' + slick.slideCount);
			});

			slickElement.slick({
				dots: false,
				arrows: true,
				fade: true,
				asNavFor: $(this).find('.gallery-slider__thumb'),
				appendArrows: $(this).find('.gallery-slider__full-nav'),
			});

			slickElementThumb.slick({
				dots: false,
				arrows: true,
				slidesToShow: 4,
				// centerMode: true,
				focusOnSelect: true,
				asNavFor: $(this).find('.gallery-slider__full'),
			});
		});
	}

	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function (url) {

							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;

						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});


		// INLINE
		activatePopup();
		// S&F callback function
		jQuery(document).on("sf:ajaxfinish", ".searchandfilter", function () {
			activatePopup();
		});
		function activatePopup() {
			$('.magnific-inline-popup').magnificPopup({
				type: 'inline',
				fixedContentPos: false,
				fixedBgPos: true,
				overflowY: 'auto',
				closeBtnInside: true,
				preloader: false,

				midClick: true,
				removalDelay: 300,
				mainClass: 'mfp-fade'
			});

		};


		// GALLERY SINGLE IMAGE
		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});

		// GALLERY IMAGE
		$('.magnific-gallery-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: true
			}
		});
	}

	accordion: {
		if ($('.block--accordion').length) {

			$('.accordion__content--wrapper').css('display', 'none'); // All items closed.

			$('.block--accordion').find('.js-accordion-trigger').on('click', function () {

				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

				if ($(this).hasClass('open')) {
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}

			});
		}
	}

	tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if (tabs) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				tab.addEventListener("click", changeTabs);
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if (tabList) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
						tabs[tabFocus].setAttribute("tabindex", -1);
						if (e.keyCode === 39) {
							tabFocus++;
							// If we're at the end, go to the start
							if (tabFocus >= tabs.length) {
								tabFocus = 0;
							}
							// Move left
						} else if (e.keyCode === 37) {
							tabFocus--;
							// If we're at the start, move to the end
							if (tabFocus < 0) {
								tabFocus = tabs.length - 1;
							}
						}

						tabs[tabFocus].setAttribute("tabindex", 0);
						tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				console.info('tabs clicked');
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');


				console.info(grandparent);
				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));

				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");
			}

		}
	}

	formLabels: {
		var formField = $('.gfield');

		formField.each(function () {
			// add classes to labels for different types of fields
			if ($(this).find('.ginput_container_select').length) {
				$(this).addClass('label-above');
			}
			if ($(this).find('.ginput_container_textarea').length) {
				$(this).addClass('label-top');
			}
			if ($(this).find('.ginput_container_fileupload').length) {
				
				$(this).find('.gfield_label').addClass('label-original');
			}
			if ($(this).find('.ginput_container_creditcard').length) {
				
				$(this).find('.gfield_label').addClass('label-original');
			}
			if ($(this).hasClass('gfield_price')) {
				
				$(this).find('.gfield_label').addClass('label-original');
			}
			
			if ($(this).find('.ginput_recaptcha').length) {
				
				$(this).find('.gfield_label').addClass('label-original');
			}
			if ($(this).find('.gfield_description').length) {
				
				$(this).find('.gfield_label').addClass('label-original');
			}
			

			
			var label = $(this).find($('label.gfield_label'));
			
			if(!label.hasClass('label-original')) {
				$(this).find($('input')).on('focusin', function () {
					label.css('opacity', 0);
				})
				$(this).find($('textarea')).on('focusin', function () {
					label.css('opacity', 0);
				})
				let valueChanged = false;
				$(this).on('input', function (e) {
					valueChanged = true;
					// console.log(valueChanged);
				});
				$(this).change(function (e) {
					valueChanged = true;
					// console.log(valueChanged);
				});

				$(this).find($('input')).on('focusout', function () {
					if (!valueChanged) {
						label.css('opacity', 1);
					}
				})
				$(this).find($('textarea')).on('focusout', function () {
					if (!valueChanged) {
						label.css('opacity', 1);
					}
				})
			}


			

		});





	}

	facilitiesMap: {
		var mapContainer = $('.map-container');
		var wilberforce = document.getElementById('wilberforce-house');
		var spencer = document.getElementById('spencer-house');
		console.log(spencer)
		mapContainer.on('click', function (e) {
			if (e.target == spencer || e.target == wilberforce) {

			}
			console.log(e.target);
		});
	}


	animatingBlobs: {
		$('svg').each(function() {
			if($(this).hasClass('animating-blob')) {
				var thisSvg = $(this);
				// our <path> element
				const path = this.querySelector("path");
				// used to set our custom property values
				// const root = document.documentElement;
	
				// let hueNoiseOffset = 0;
				let noiseStep = 0.0035;
			
				// const simplex = new SimplexNoise();				
				const noise2D = createNoise2D();
				
				const points = createPoints(thisSvg);

				(function animate() {
				  path.setAttribute("d", spline(points, 1, true));
				
				  // for every point...
				  for (let i = 0; i < points.length; i++) {
					const point = points[i];
				
					// return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
					const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
					const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
					// map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
					const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
					const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);
				
					// update the point's current coordinates
					point.x = x;
					point.y = y;
				
					// progress the point's x, y values through "time"
					point.noiseOffsetX += noiseStep;
					point.noiseOffsetY += noiseStep;
				  }
			  
				//   const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
				//   const hue = map(hueNoise, -1, 1, 0, 360);
			  
				//   root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
				//   root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
				//   document.body.style.background = `hsl(${hue + 60}, 75%, 5%)`;
			  
				//   hueNoiseOffset += noiseStep / 6;
			  
				  requestAnimationFrame(animate);
				})();
	
				function map(n, start1, end1, start2, end2) {
				  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
				}
	
				function noise(x, y) {
				//   return simplex.noise2D(x, y);
				  return noise2D(x, y); 
				}
	
				function createPoints(thisSvg) {
					
				  const points = [];
				  // how many points do we need
				  const numPoints = 6;
				  // used to equally space each point around the circle
				  const angleStep = (Math.PI * 2) / numPoints;
				  // the radius of the circle
				  
				  const rad = thisSvg.width() / 2;
	
				
				  for (let i = 1; i <= numPoints; i++) {
					// x & y coordinates of the current point
					const theta = i * angleStep;
				
					const x = 100 + Math.cos(theta) * rad;
					const y = 100 + Math.sin(theta) * rad;
				
					
					// store the point's position
					points.push({
					  x: x,
					  y: y,
					  // we need to keep a reference to the point's original point for when we modulate the values later
					  originX: x,
					  originY: y,
					  // more on this in a moment!
					  noiseOffsetX: Math.random() * 1000,
					  noiseOffsetY: Math.random() * 1000
					});
					  }
				  
					  return points;
					}
					
					// document.querySelector("path").addEventListener("mouseover", () => {
					//   noiseStep = 0.01;
					// });
					
					// document.querySelector("path").addEventListener("mouseleave", () => {
					//   noiseStep = 0.005;
					// });
			};	
		});
		
	}


	loadingAnimations: {
		$('.block--statistic-columns').each(function () {
			let trigger = $(this)

			ScrollTrigger.create({
				trigger: trigger,
				start: "top bottom",
				once: true,
				onEnter: function () {
					$('.js-value').each(function () {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).text()
						}, {
							duration: 8000,
							easing: 'swing',
							step: function (now) {
								$(this).text(Math.ceil(now));
							}
						});
					});

				},
			});

		})

		// twk animate on scroll
		function AnimateOnScroll() {
			const elements = document.querySelectorAll('[twk-aos]');

			if (!elements) return;

			[...elements].forEach(element => {
				const twkAosTrigger = element.getAttribute('twk-aos-trigger') ? element.getAttribute('twk-aos-trigger') : 'bottom-=150px';
				const twkAosMarker = element.hasAttribute('twk-aos-marker');

				ScrollTrigger.create({
					trigger: element,
					start: `top ${twkAosTrigger}`,
					end: 'bottom bottom',
					markers: twkAosMarker,
					onEnter: () => element.classList.add('twk-aos-animate'),
				});
			})
		}
		AnimateOnScroll();





		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}

	parallaxAnimationHistory: {
		let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

		if($('.block--featured-section').length) {
			$('.block--featured-section').each(function() {
				var trigger = $(this)
				var images = $($(this).find('.js-featured-images'));
				var featPin = $(this).find('.js-featured-pin');
				var featText = $(this).find('.js-featured-text');
				let endPoint;
				if(window.innerWidth < 767) {
					endPoint = "+=100%";
				} else {
					endPoint = "+=100%";
				}
				console.log(trigger);				
				gsap.from (featText, {
					// x: "-10vw",
					scrollTrigger: {						
						scrub: 1,
						once: false,
						trigger: trigger,
						start: "top center-=250px",
						pin: featPin,
					    end: endPoint ,
						// end: "bottom bottom",
					}
				})
				  

				// const tl = gsap.timeline({
				// 	scrollTrigger: {
				// 	  trigger: trigger,
				// 	  start: "top bottom",
				// 	  end: "bottom bottom",
				// 	  scrub: true,					  
				// 	}
				//   });
				// gsap.utils.toArray(images).forEach(layer => {
				// 	const depth = layer.dataset.depth;
				// 	const movement = -(layer.offsetHeight * depth)
				// 	tl.to(layer, {y: movement, ease: "none"}, 0)					
				// });
			})

		}
	}

	historyLoadingAnimations : {
		if($('body').hasClass('page-template-tpl-history')) {
			$('section').each(function() {
				let trigger = $(this);
				
				let bannerTitle = $(this).find('.js-history-title');
				let bannerIntro = $(this).find('.js-history-intro');
				let bannerImage = $(this).find('.js-history-image');
				let largeBgImage = $(this).find('.js-history-large-bg-image img');
				let largeBgSubtitle = $(this).find('.js-history-large-bg-subtitle');
				let largeBgTitle = $(this).find('.js-history-large-bg-title');
				let largeBgText = $(this).find('.js-history-large-bg-text');
				let headteacherBubble = $(this).find('.js-headteacher-bubble');				
				let headteacherLine = $(this).find('.js-history-headteacher-line');				
				let textImageImage = $(this).find('.js-history-text-image-image');
				let textImageText = $(this).find('.js-history-text-image-text');
				let centeredText = $(this).find('.js-centered-text');
				let centeredTextImage = $(this).find('.js-centered-text-image');
				let crests = $(this).find('.js-history-crests');
				let crestsLine = $(this).find('.js-history-crests-line');
				
				
			
				let histTl = gsap.timeline({			
					scrollTrigger: {
					  trigger: trigger,
					  start: "top center+=200px",
					}
				  });
		
				
				if(bannerTitle.length> 0) {
					histTl.from(bannerTitle, {
					y: "80%",
					opacity: 0,
					duration: 1,
					ease: "power2.out"
				  })
				}
				if(bannerIntro.length> 0) {
					histTl.from(bannerIntro, {
					x: "100%",
					opacity: 0,
					duration: 1,
					ease: "power2.in"
				  },"<+=0.5")
				}
				if(bannerImage.length> 0) {
					histTl.from(bannerImage, {
					stagger: 0.4,
					opacity: 0,
					duration: 0.8,
					ease: "power4.inOut"
				  },">")

				}
				if(largeBgImage.length> 0) {
					histTl.from(largeBgImage, {					
					opacity: 0, 
					duration: 0.7,
					delay: 0.4,
					ease: "power2.out"
				  },"")
				}
				if(largeBgTitle.length> 0) {
					histTl.from(largeBgTitle, {
					y: "100%",
					opacity: 0, 
					duration: 1.3,
					ease: "power2.in"
				  },">-=0.7")
				}
				// if(largeBgSubtitle.length> 0) {
				// 	histTl.from(largeBgSubtitle, {
				// 	y: "100%",
				// 	opacity: 0,
				// 	duration: 1,
				// 	ease: "power2.in"
				//   },"<+=0.6")
				// }
				if(largeBgText.length> 0) {
					histTl.from(largeBgText, {					
					opacity: 0, 
					xPercent: 100,
					duration: 1,
					ease: "power2.out"
				  },">-=0.4")
				}
				if(textImageImage.length> 0) {
					histTl.from(textImageImage, {					
					opacity: 0,
					duration: 1.3,
					ease: "power2.in",
				  },">")
				}
				if(textImageText.length> 0) {
					histTl.from(textImageText, {					
					y: "100%",
					duration: 1.3,
					opacity: 0,
					ease: "power2.in",
				  },"<")
				}
				if(headteacherLine.length> 0) {
					histTl.from(headteacherLine, {					
					width: 0,
					duration: 1.5,
					ease: "power4.inOut",			
					delay: 0.2,							
				  },"")
				}
				if(headteacherBubble.length> 0) {
					histTl.from(headteacherBubble, {					
					scale: 0,
					duration: 1.3,
					ease: "power4.out",
					// delay: 0.5,
					stagger: 0.5,
				  },">")
				}				
				if(centeredText.length> 0) {
					histTl.from(centeredText, {					
					y: "100%",
					opacity: 0,
					duration: 1.3,
					ease: "power4.out",					
					stagger: 0.5,
				  },">")
				}				
				if(centeredTextImage.length> 0) {
					histTl.from(centeredTextImage, {					
					scale: 0,
					duration: 1.3,
					ease: "power4.out",					
					stagger: 0.5,
				  },">=-0.3")
				}
				if(crests.length> 0) {
					histTl.from(crests, {					
					scale: 0,
					duration: 1,
					ease: "power4.out",					
					stagger: 0.3,
				  },">")
				}
				if(crestsLine.length> 0) {
					histTl.from(crestsLine, {					
					width: 0,
					duration: 1.5,
					ease: "power4.inOut",										
				  },">=-0.6")
				}
				
			});
		}
	}
});


 